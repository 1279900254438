@font-face {
  font-family: "RobotoMedium";
  src: url("../public/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "RobotoBlack";
  src: url("../public/fonts/Roboto/Roboto-Black.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "RobotoBold";
  src: url("../public/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "RobotoRegular";
  src: url("../public/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../public/fonts/Helveticaneue/HelveticaNeueMedium.ttf") format("truetype");
  src: url('../public/fonts/Helveticaneue/HelveticaNeueMedium.otf') format('opentype');
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "Helvetica Neue Bold";
  src: url("../public/fonts/Helveticaneue/HelveticaNeueMedium.ttf") format("truetype");
  src: url('../public/fonts/Helveticaneue/HelveticaNeueMedium.otf') format('opentype');
  font-display: swap;
  unicode-range: U+000-5FF;
}

html,
body,
.pageContent,
#root {
  height: 100%;
  min-height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#mainContent {
  flex: 1 0 auto;
  min-height: 100%;
}

.pageContainer {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}


footer {
  flex-shrink: 0;
}

#headerDiv {
  flex-shrink: 0;
}

.text-small {
  font-size: small;
}

.btn_style {
  width: 90%;
  background-color: #075AAE !important;
  color: #fff;
  border: 0px;
  height: 1.8rem;
  padding: 0.05rem 1.2rem;
  font-size: 20px;
  font-family: 'RobotoRegular';
  font-weight: bold !important;
  letter-spacing: 1px;
}

.border_radius_2 {
  border-radius: 0.2rem !important;
}

.theme_color {
  color: #075aae !important;
}

.theme_background_color {
  background: #075aae !important;
}


.custom_column {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

p {
  margin: 0 0 0 0.1rem;
  font-size: 14px;

}

.card .card-header,
.card .card-body,
.card .card-footer {
  padding: 0.5rem;
}


.card_custom {
  min-height: 490px;
  box-shadow: 0px 3px 10px #00000019;
  border-radius: 10px;
  cursor: pointer;
}

.card_custom .card-header .p-listing {
  font-size: 14px;
  font-family: 'RobotoRegular';
  font-weight: 100;
  color: #1D1D1D
}

.card_custom .card-header .materialstatus {
  font-size: 16px;
  /* font-family: 'RobotoRegular'; */
  font-weight: bold !important;
}

.card_custom .card-header .price {
  margin-top: 10px;
}

.img_rep_block {
  width: 100%;
  height: 288px;
  display: block;
  min-height: 250px;
  max-width: 100%;
  object-fit: cover;
}

.img-responsive {
  height: inherit;
  object-fit: cover;
  width: 100%;
}

.listing_thumb {
  min-height: 288px;
}

.card_custom_title {
  color: #075AAE;
  font-weight: bold;
  font-size: 17px;
  font-family: 'RobotoRegular';
  text-transform: capitalize;
}

.card_custom_subtitle {
  color: #1D1D1D;
  font-size: 16px;
  font-family: 'RobotoRegular';
  text-transform: capitalize;
}

.vertical_align_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  font-size: 12px;
  height: 100px;
  background: #EBEBEB !important;
}

/* ////////////////////////////////////    Font Size   /////////////// */
.font_size_12 {
  font-size: 12px !important;
}

.font_size_14 {
  font-size: 14px !important;
}

.font_size_16 {
  font-size: 16px !important;
}

.font_size_18 {
  font-size: 18px !important;
}

.font_size_20 {
  font-size: 20px !important;
}

.topnav a.active {
  border-bottom: 5px solid #075AAE !important;
}


.sticky {
  background: white;

}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.6s ease;
  background: white;
  z-index: 1000;
}


@media only screen and (max-width: 1600px) {
  .pageContainer {
    max-width: 1366px;
  }
}
@media only screen and (max-width: 1366px) {
  .pageContainer {
    max-width: 1280px;
  }
  .listing_thumb {
    min-height: 220px;

  }
  .img_rep_block {
    height: 220px;
    min-height: 220px;
  }
  .card_custom {
    min-height: 430px;
  }
}
@media only screen and (max-width: 1280px) {
  .pageContainer {
    max-width: 1180px;
  }
  .listing_thumb {
    min-height: 220px;

  }
  .img_rep_block {
    height: 220px;
    min-height: 220px;
  }
  .card_custom {
    min-height: 410px;
  }
}


.pagination-button{
  cursor: pointer;
}
.pagination-text{
  margin: 0 10px;
}